<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 悦淘加油特权
 * @Date: 2020-11-12 16:33:24
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-11-12 17:54:19
 * @FilePath: /yue_quanzhan_h5_own/src/views/localLife/comeOn/privileges.vue
-->
<template>
  <div>
    <img :src="courseTopImg" alt="">
    <div class="videbox" @click="couimg">
      <img v-if="so" :src="courseVideoCover" alt="">
      <video v-if="so2" ref="vueMiniPlayer" class="visw" width="240" height="240">
        <source :src="courseVideo" type="video/mp4">
        <source :src="courseVideo" type="video/ogg">
      </video>
      <div v-if="s3" class="viswso"><img src="@/assets/images/play.png" alt=""></div>
    </div>
    <img :src="courseBottomImg" alt="">
  </div>
</template>

<script>
import { fetchRefuelingHelpList } from '@/services/comeOn'

export default {
  data() {
    return {
      courseBottomImg: '',
      courseTopImg: '',
      courseVideo: '',
      courseVideoCover: '',
      so: true,
      so2: false,
      s3: false
    }
  },
  created() {
    this.getHelpList()
  },
  methods: {
    getHelpList() {
      fetchRefuelingHelpList().then(res => {
        this.courseTopImg = res.data.courseTopImg
        this.courseBottomImg = res.data.courseBottomImg
        this.courseVideo = res.data.courseVideo
        this.courseVideoCover = res.data.courseVideoCover
      })
    },
    couimg() {
      this.so = false
      this.so2 = true
      if (this.$refs.vueMiniPlayer.paused || this.so === true) {
        this.s3 = false
        this.$refs.vueMiniPlayer.play()
      } else {
        this.s3 = true
        this.$refs.vueMiniPlayer.pause()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.videbox {
  width: 240px;
  margin: 0 auto;
  border-radius: 5px;

  .visw {
    border-radius: 5px;
    position: relative;
  }

  .viswso {
    position: absolute;
    left: 42%;
    top: 35%;
    z-index: 99;

    img {
      width: 61px;
    }
  }
}
</style>
